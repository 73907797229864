import React from "react"
import  { Redirect } from 'react-router-dom'
//import Button from '@mui/material/Button'
import Cards from "./Cards"
import { Button } from "@material-tailwind/react"
import { useAuth0 } from '@auth0/auth0-react'
import loading from "../assets/loading.svg"
import Box from '@mui/material/Box'

import "@fontsource/orbitron/600.css";

const Home = () => {
  const { user, isLoading, isAuthenticated, loginWithRedirect } = useAuth0()

  const login = () => loginWithRedirect()

  if (isAuthenticated) {
    if (user.email) {
      // TODO: register the user in the backend
      // TODO: if successfull, store the fact that this user has been registered in local storage
      //       such that next time we don't try to register the user again
      //       that removes the load on the backend
      // cf. https://stackoverflow.com/questions/71876755/save-state-to-localstorage
    }
    return <Redirect to="/home" />
  }

  return (
    <div className="h-full flex bg-gradient-to-t from-teal-500 to-teal-200">
      <Box
         justifyContent="center"
         alignItems="center"
         textAlign="center"
         display="flex"
         sx={{ width: 1 }}
      >
        <Box
         justifyContent="center"
         alignItems="center"
         textAlign="center"
         display="absolute"
         sx={{ width: 1 }}
        >
        <p className="text-4xl font-orbitron text-center text-white mb-3">AUTORIZE</p>
        {isLoading
          ? <img src={loading} alt="Loading" className="m-auto" width={30} height={30} />
          : <Button className="text-xl text-white" variant="outlined" onClick={login}>
              Login
            </Button>
        }
        </Box>
      </Box>
    </div>
  )
}

export default Home
