import { useState, useEffect } from "react"
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react"
import Loading from "../components/Loading"
import { Sessions } from "../components/Sessions"
import { Chargers } from "../components/Chargers"
import NavBar from "../components/NavBar"
import Header from "../components/Header"
import { getActiveSessions, getChargers, stopSession, setChargingMode } from "../utils/userRequests"

/*
const dummySessions = [
  {
    txId: 1,
    chargerDescription: "Klix BV",
    startDate: new Date(),
    amountChargedInWatt: 2335,
    maxChargingAmountInWatt: 11000,
    pricePerKWh: 59, // in cents
  },
  {
    txId: 2,
    chargerDescription: "VUB",
    startDate: new Date(),
    amountChargedInWatt: 234,
    maxChargingAmountInWatt: 9000,
    pricePerKWh: 29, // in cents
  },
]

const dummyChargers = [
  {
    description: "Klix BV",
    address: "Klix BV",
    isOnline: true,
    isGreenCharging: true,
    chargepointId: "1234567890",
    connectorCount: 2,
    pricePerKWh: 59,
    maxAmperage: 32,
    backendUrl: "wss://ocpp.autorize.eu",
  },
]
*/

const UserHome = () => {
  // TODO: move this boilerplate auth code to a custom hook `withAccessToken` that does all this (including getting the token) and returns the token
  const {
    error: authError,
    getAccessTokenSilently,
    logout,
  } = useAuth0()

  const getAccessToken = async () => {
    try {
      return await getAccessTokenSilently()
    } catch (_error) {
      // Authentication problem, log the user out and go back to home page
      logout({ logoutParams: { returnTo: window.location.origin } });
    }
  }

  if (authError !== undefined) {
    // Authentication problem, log the user out and go back to home page
    logout({ logoutParams: { returnTo: window.location.origin } });
  }

  const [activeSessions, setActiveSessions] = useState()
  const [chargers, setChargers] = useState()

  const stop = async (session) => {
    try {
      const token = await getAccessToken()
      // TODO: change internalChargepointId to chargepointId in the backend and here
      const { success, reason } = await stopSession(token, session.internalChargepointId, session.txId)
      if (success) {
        const newSessions = activeSessions.filter(s => s.txId !== session.txId)
        setActiveSessions(newSessions)
      }
      else {
        console.log("Failed to stop session. " + reason)
      }
  
      return success
    } catch (_e) {
      return false
    }
  }

  const setMode = async (charger, mode) => {
    try {
      const token = await getAccessToken()
      const { success, reason } = await setChargingMode(token, charger.internalChargepointId, mode)

      if (success) {
        const newChargers = chargers.map(c => c.internalChargepointId === charger.internalChargepointId ? { ...c, isGreenCharging: mode === 'GREEN' } : c)
        setChargers(newChargers)
      }
      else {
        console.log("Failed to set charging mode. " + reason)
      }

      return success
    } catch (_e) {
      return false
    }
  }

  const fetchActiveSessions = async () => {
    const token = await getAccessToken()
    const res = await getActiveSessions(token)

    if (res.success) {
      setActiveSessions(res.sessions)
    }
  }

  const fetchChargers = async () => {
    const token = await getAccessToken()
    const res = await getChargers(token)

    if (res.success) {
      setChargers(res.chargers)
    }
  }

  useEffect(fetchActiveSessions, [])
  useEffect(fetchChargers, [])

  return (
    <div className="bg-light-gray min-h-screen">
      <Header title="Home" />
      <div className="flex flex-col items-center px-4">
          <Sessions sessions={activeSessions} stopSession={stop} />
          <Chargers chargers={chargers} setChargingMode={setMode} />
      </div>
      <NavBar tab='Home' />
    </div>
  )
}

export default withAuthenticationRequired(UserHome, {
  onRedirecting: () => <Loading />,
})
