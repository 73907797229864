import EvStationIcon from '@mui/icons-material/EvStation'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Stack from '@mui/material/Stack'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Skeleton from "@mui/material/Skeleton"
import { useState } from 'react'
import { stopSession } from '../utils/userRequests'

export const SessionSkeleton = () => {
  return (
    <>
      <Stack direction="row" spacing={2} alignItems="center" mb={2}>
        <Skeleton variant="circular">
          <EvStationIcon color="primary" sx={{ fontSize: 40 }} />
        </Skeleton>
        
        <Skeleton variant="rectangular" width="60%">
            <Typography variant="h6" component="div">
              .
            </Typography>
          </Skeleton>
      </Stack>

      <Stack direction="row" justifyContent="space-between" mb={1}>
        <Skeleton variant="rectangular" width="100%">
          <Typography variant="body2" color="text.secondary">
            .
          </Typography>
        </Skeleton>
        <Skeleton variant="rectangular" width="100%">
          <Typography variant="body2" color="text.secondary">
            .
          </Typography>
        </Skeleton>  
      </Stack>

      <Skeleton variant="rectangular" width="100%">
        <Typography variant="body1">
          .
        </Typography>
      </Skeleton>
    </>
  )
}

const SessionCard = ({ session, stopSession }) => {
  const progress = (session.amountChargedInWatt / session.maxChargingAmountInWatt) * 100
  const kwhCharged = (session.amountChargedInWatt / 1000).toFixed(2)
  const maxKwh = (session.maxChargingAmountInWatt / 1000).toFixed(2)
  const cost = ((session.amountChargedInWatt / 1000) * (session.pricePerKWh / 100)).toFixed(2)

  const [isStopping, setIsStopping] = useState(false)

  const stop = async () => {
    console.log("Stopping session: " + session.txId)
    setIsStopping(true)
    const stopped = await stopSession(session)
    if (!stopped) {
      setIsStopping(false)
    }
  }

  return (
    <Card sx={{ minWidth: 275, mb: 2 }}>
      <CardContent>
        <Stack direction="row" spacing={2} alignItems="center" mb={2}>
          <EvStationIcon color="primary" sx={{ fontSize: 40 }} />
          <div>
            <Typography variant="h6" component="div">
              {session.chargerDescription}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Started: {session.startDate.toLocaleTimeString()}
            </Typography>
          </div>
        </Stack>

        <Box sx={{ width: '100%', mb: 2 }}>
          <LinearProgress 
            variant="determinate" 
            value={progress} 
            sx={{ height: 10, borderRadius: 5 }}
          />
        </Box>

        <Stack direction="row" justifyContent="space-between" mb={1}>
          <Typography variant="body2" color="text.secondary">
            Charged: {kwhCharged} kWh
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Max: {maxKwh} kWh
          </Typography>
        </Stack>

        <Typography variant="body1">
          Cost: €{cost} ({session.pricePerKWh}¢/kWh)
        </Typography>
      </CardContent>
      <CardActions>
        <Button 
          variant="contained" 
          color="error" 
          size="small"
          disabled={isStopping}
          onClick={() => stop(session)}
        >
          {isStopping ? 'Stopping...' : 'Stop Session'}
        </Button>
      </CardActions>
    </Card>
  )
}

export const Sessions = ({ sessions, stopSession }) => {
  const [expanded, setExpanded] = useState(true)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const content = !sessions || sessions.length === 0 ? (
    <Typography variant="body1" color="text.secondary">
      No active charging sessions
    </Typography>
  ) : (
      sessions.map((session) => (
          <SessionCard key={session.txId} session={session} stopSession={stopSession} />
      ))
  )

  return (
    <Box sx={{ mt: 2, width: '100%', maxWidth: '400px' }}>
      <Box 
        sx={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer'
        }}
        onClick={handleExpandClick}
      >
        <Typography variant="h5" sx={{ flexGrow: 1 }}>
          Active Sessions
        </Typography>
        <IconButton
          sx={{
            transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: '0.3s'
          }}
        >
          <ExpandMoreIcon />
        </IconButton>
      </Box>
      <Collapse in={expanded} timeout="auto" sx={{ mt: 1 }} unmountOnExit>
        {!sessions ? (
          <SessionSkeleton />
        ) :
          content
        }
      </Collapse>
    </Box>
  )
}
